<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Kategori Jenis FPP</h5>
      </template>
      <div
        class="search-wrapper d-flex align-items-center justify-content-between"
      >
        <div>
          <b-button
            class="mb-3 mr-2"
            variant="pgiBtn"
            name="kategoriJenisFPPAddBtn"
            @click="$bvModal.show('m-create-kategori-jenis-fpp')"
            ><i class="fa fa-plus fs-6"></i> Tambah Data</b-button
          >
        </div>
      </div>

      <b-table
        bordered
        hover
        show-empty
        :busy="isBusy"
        :items="kategoriJenis"
        :fields="fields"
        :perPage="perPage"
        responsive
        class="mb-3"
      >
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="secondary"></b-spinner>
          </div>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button
              class="mr-1 btn btn-sm btn-warning"
              v-b-tooltip.hover
              title="Edit"
              name="btnEditKategoriJenisFPP"
              @click="showModalEdit(`${row.item.id}`, `${row.item.name}`)"
            >
              <i class="fa fa-pencil"></i>
            </b-button>
            <b-button
              class="ml-1 btn btn-sm btn-danger"
              v-b-tooltip.hover
              title="Delete"
              name="btnDeleteKategoriJenisFPP"
              @click="
                showSweetAlertDelete(`${row.item.id}`, `${row.item.name}`)
              "
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        class="pagination-wrapper d-flex align-items-center justify-content-between"
      >
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="onPageChange"
        ></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal
      id="m-create-kategori-jenis-fpp"
      class="modal-lg"
      title="Kategori Jenis FPP"
      ref="my-modal-kategori-jenis-fpp"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <b-form @submit.prevent="onConfirm">
        <b-form-group
          id="fg-create-kategori-jenis-fpp"
          label="Kategori Jenis"
          label-for="input-kategori-jenis-fpp"
          class="required"
        >
          <b-form-input
            id="input-kategori-jenis-fpp"
            type="text"
            v-model="form.name"
            placeholder="Masukkan kategori jenis"
          ></b-form-input>
          <span
            v-if="!$v.form.name.required && $v.form.name.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</span
          >
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit"
            ><i class="fa fa-save"></i> Simpan</b-button
          >
          <b-button class="ml-2" variant="dark" @click="hideModal"
            ><i class="fa fa-times"></i> Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <!-- MODAL EDIT -->
    <b-modal
      id="m-edit-kategori-jenis-fpp"
      class="modal-lg"
      title="Ubah Kategori Jenis FPP"
      ref="my-modal-kategori-jenis-fpp"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <b-form @submit.prevent="onConfirmEdit">
        <b-form-group
          id="fg-edit-kategori-jenis-fpp"
          label="Kategori Jenis"
          label-for="edit-kategori-jenis-fpp"
          class="required"
        >
          <b-form-input
            id="edit-kategori-jenis-fpp"
            type="text"
            v-model="formEdit.name"
          ></b-form-input>
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit"
            ><i class="fa fa-save"></i> Simpan Perubahan</b-button
          >
          <b-button class="ml-2" variant="dark" @click="hideModal"
            ><i class="fa fa-times"></i> Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      form: {
        name: null,
      },
      formEdit: {
        id: null,
        name: null,
      },
      formDelete: {
        activeName: null,
        activeId: null,
      },
      fields: [
        { key: "name", label: "Kategori Jenis" },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      kategoriJenis: [],
      isModalVisible: false,
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      loading: false,
      query: "",
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },

    formEdit: {
      name: {
        required,
      },
    },
  },

  methods: {
    //----------------- Init Function -----------------//
    init() {
      this.initStatus = 0;

      let getAllData = this.$axios
        .get("api/internal-memo/kategori-jenis")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (var data of datas.data) {
              this.kategoriJenis.push({
                id: data.id,
                name: data.name,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.updateTotalItem(datas.total);
          }
        });

      Promise.all([getAllData])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch(() => {
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange: function () {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getKategoriJenis();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      this.totalViewText = this.totalViewText = this.from + " - " + this.limit;
    },

    //-----------------  Callback Function -----------------//
    getKategoriJenis: function () {
      this.toggleBusy();

      this.$axios
        .get("api/internal-memo/kategori-jenis" + this.query)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            this.kategoriJenis = [];

            for (var data of datas.data) {
              this.kategoriJenis.push({
                id: data.id,
                name: data.name,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.toggleBusy();
            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        name: null,
      };

      this.formEdit = {
        id: null,
        name: null,
      };

      this.formDelete = {
        activeName: null,
        activeId: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-kategori-jenis-fpp"].hide();
      this.resetForm();
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-kategori-jenis-fpp");
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-kategori-jenis-fpp");
      }
    },

    //-----------------  Create Function -----------------//
    showSweetAlertCreate: function () {
      let form = {
        name: this.form.name,
      };

      this.$swal({
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan penyimpanan data kategori jenis.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penyimpanan data kategori jenis sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori-jenis", form)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori jenis berhasil disimpan.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getKategoriJenis();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori jenis gagal disimpan.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });

              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });

          this.resetForm();
        }
      });
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (id, name) {
      this.formEdit.id = id;
      this.formEdit.name = name;

      this.$bvModal.show("m-edit-kategori-jenis-fpp");
    },

    showSweetAlertEdit: function () {
      let formEdit = {
        name: this.formEdit.name,
        id: this.formEdit.id,
      };

      this.$swal({
        html: "<strong>Konfirmasi</strong><span>, untuk melanjutkan perubahan data kategori jenis.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: true,
        closeOnCancel: true,
        reverseButtons: true,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Perubahan data kategori jenis sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori-jenis/" + formEdit.id, formEdit)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori jenis berhasil diubah.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getKategoriJenis();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori jenis gagal diubah.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });

              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });

          this.resetForm();
        }
      });
    },

    //-----------------  Delete Function -----------------//
    showSweetAlertDelete: function (id, name) {
      this.formDelete.activeId = id;
      this.formDelete.activeName = name;

      var jenisName = this.formDelete.activeName;
      var contentJenis = document.createElement("div");
      contentJenis.innerHTML =
        '<strong>Konfirmasi</strong>, untuk melanjutkan proses penghapusan data kategori jenis <strong>"' +
        jenisName +
        '"</strong>?';

      this.$swal({
        title: "Konfirmasi",
        html: contentJenis,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya. Hapus!",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penghapusan data kategori jenis sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .delete(
              "api/internal-memo/kategori-jenis/delete/" +
                this.formDelete.activeId
            )
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data kategori jenis berhasil dihapus.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1000,
                });

                this.resetForm();
                this.getKategoriJenis();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: "Gagal",
                text: "Data kategori jenis gagal dihapus.",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });

              this.resetForm();
            });
        } else {
          this.$swal({
            title: "Batal",
            text: "Proses ini telah dibatalkan :)",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });

          this.resetForm();
        }
      });
    },
  },

  //-----------------  Initial Function -----------------//
  created: function () {
    this.init();
  },
};
</script>

<style lang="scss">
.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.required label::after {
  content: "*";
  color: #f79483;
}
</style>
